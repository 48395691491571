* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

:root {
    --light: #F9FDFF;
    --light-2: #EBFAFF;
    --accent: #0E85AB;
    --dark: #021014;
    --grey: #C0C0C0;
}

body {
    background-color: var(--light);
    font-family: 'Source Sans 3', sans-serif;
    padding-top: 72px;
    scroll-behavior: smooth;
}

p {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: var(--dark);
}

h1 {
    font-family: 'Playfair Display', serif;
    font-size: clamp(24px, 4.5vw, 40px);
    color: var(--light);
}

h2 {
    font-family: 'Playfair Display', serif;
    font-size: clamp(24px, 3.5vw, 32px);
    color: var(--dark);
}

h3 {
    font-family: 'Source Sans 3', sans-serif;
    font-size: 20px;
    color: var(--dark);
}

.container {
    max-width: 1280px;
    margin: 0 auto;
    padding-right: 32px;
    padding-left: 32px;
}

img {
    object-fit: cover;
}

button {
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

button.primary-1 {
    background-color: var(--accent);
    border: 1px solid var(--accent);
    color: var(--light);
}

button.primary-1:hover {
    background-color: var(--light);
    border: 1px solid var(--light);
    color: var(--dark);
}

button.primary-2 {
    background-color: var(--accent);
    border: 1px solid var(--accent);
    color: var(--light);
}

button.primary-2:hover {
    background-color: var(--dark);
    border: 1px solid var(--dark);

}

button.secondary {
    background-color: var(--light);
    border: 1px solid var(--accent);
    color: var(--accent);
}

button.secondary:hover {
    background-color: var(--dark);
    border: 1px solid var(--dark);
    color: var(--light);
}

.page-heading {
    background-color: var(--accent);
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pd-56 {
    padding-top: 56px;
    padding-bottom: 56px;
}

.pd-32 {
    padding-top: 32px;
    padding-bottom: 32px;
}

/* media queries */
@media screen and (max-width: 1023px) {
    .container {
        padding-right: 24px;
        padding-left: 24px;
    }
}

@media screen and (max-width: 767px) {
    .container {
        padding-right: 20px;
        padding-left: 20px;
    }

    .pd-56 {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}