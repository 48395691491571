.portfolio-text {
  text-align: center;
  width: 90%;
  margin: 0 auto 16px auto;
}

.image-gallery {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.image-gallery .thumb-image {
  cursor: pointer;
  width: calc(33.3333% - 8px);
  margin-bottom: 8px;
}

.image-gallery .overlay {
  position: fixed;
  top: 72px;
  left: 0;
  width: 100%;
  height: calc(100vh - 72px);
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  z-index: 99;
}

.image-gallery .big-image {
  display: block;
  max-width: 65%;
  max-height: 90%;
  margin: 60px auto;
}

.image-gallery .arrows {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  width: 100%;
}

.image-gallery .arrows img {
  padding: 16px;
  margin: 0 16px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  cursor: pointer;
}

@media screen and (max-width: 1023px) {
  .image-gallery .thumb-image {
    width: calc(50% - 6px);
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 767px) {
  .image-gallery .thumb-image {
    width: 100%;
    margin-bottom: 8px;
  }

  .portfolio-text {
    width: 100%;
  }

  .image-gallery .arrows img {
    padding: 8px;
    margin: 0 8px;
    width: 48px;
    height: 48px;
  }
}