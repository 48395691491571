.service-page .light-background {
    background-color: var(--light-2);
}

.service-page .container {
    text-align: center;
}

.service-page h2 {
    margin-bottom: 16px;
}

.service-page .service-description {
    max-width: 80%;
    margin: 0 auto;
}

.service-page .wall-upholstery .card {
    width: calc(50% - 0.75vw);
}

@media screen and (max-width: 1023px) {

    .service-page .service-description {
        max-width: 90%;
    }
}

@media screen and (max-width: 767px) {
    .service-page .service-description {
        max-width: 100%;
    }
}