.footer {
    background-color: var(--dark);
}

.footer a {
    text-decoration: none;
    color: var(--light);
}

.footer p {
    color: var(--light);
}

.footer a:hover {
    text-decoration: underline;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    padding: 48px 0 32px 0;
}

.footer-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 24px;
}

.footer-brand {
    display: flex;
    flex-direction: column;
}

.footer-brand p {
    margin: 26px 0;
    max-width: 180px;
}

.footer-logo {
    width: 320px;
}

.footer-logo img {
    width: 100%;
}

.footer-socials {
    display: flex;
}

.footer-socials a {
    margin-right: 8px;
}

.footer-socials img {
    width: 30px;
    height: 30px;
}

.footer-socials img:hover {
    transform: scale(1.1);
}


.footer-info-column a,
.footer-links-column a {
    display: flex;
    align-items: center;
    margin: 8px 0;
}

.footer-info-column img {
    margin-right: 16px;
}

.footer-info img {
    width: 30px;
    height: 30px;
}

.footer-links-column,
.footer-info-column {
    display: flex;
    flex-direction: column;
}

.footer-copyright {
    padding: 32px 0;
}

.footer hr {
    opacity: 0.1;
}

.footer-copyright p {
    opacity: 0.6;
    text-align: center;
}

.cta {
    background-color: var(--accent);
}

.cta .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cta h2 {
    color: var(--light);
}

.cta button {
    padding: 12px 24px;
    font-weight: bold;
    font-size: 24px;
}

@media screen and (max-width: 1023px) {}

@media screen and (max-width: 767px) {
    .cta .container {
        flex-direction: column;
        text-align: center;
    }

    .cta h2 {
        margin-bottom: 16px;
        font-size: 22px;
    }

    .cta button {
        font-size: 20px;
    }

    .footer-content div {
        width: 100%;
    }

    .footer-content {
        flex-direction: column;
        align-items: center;
    }


    .footer-info, .footer-brand {
        padding-bottom: 32px;
        margin-bottom: 32px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .footer-copyright p {
        text-align: left;
    }
}