.contact-us {
    position: relative;
}

.contact-us .bg {
    height: calc(100% - 80px);
    z-index: -1;
    width: 50%;
    background-color: var(--light-2);
    position: absolute;
    top: 80px;
    left: 0
}

.contact-us .input-fields {
    margin: 24px 0;
}

.contact-us .input-field {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

}

.contact-us .input-field span {
    color: red;
}

.contact-us input,
.contact-us textarea {
    border: 1px solid var(--accent);
    border-radius: 5px;
    padding: 12px;
    margin-top: 8px;
    width: 100%;
    outline: 0;
    font-family: 'Source Sans 3', sans-serif;
    font-size: 16px;
}

.contact-us textarea {
    resize: none
}

.contact-us .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contact-us .form-container,
.contact-us .contact-info {
    padding: 56px;
    width: 50%;
}

.contact-us .contact-info {
    font-size: 20px;
}

.contact-us .contact-info img {
    width: 24px;
    height: 24px;
}

.contact-info div {
    display: flex;
    align-items: center;
    margin-top: 48px;
}

.contact-info div:first-of-type {
    margin-top: 0;
}

.contact-info a {
    text-decoration: none;
    color: var(--dark);
}

.contact-info .contact-icon {
    background-color: var(--light-2);
    margin-right: 16px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-info img {
    filter: brightness(0%) invert(38%) sepia(65%) saturate(7080%) hue-rotate(177deg) brightness(100%) contrast(89%);
}

@media screen and (max-width: 1023px) {
    .contact-us .container {
        flex-direction: column;
    }

    .contact-us .bg {
        display: none;
    }

    .contact-us .form-container,
    .contact-us .contact-info {
        max-width: 560px;
        width: 100%;
    }
}

@media screen and (max-width: 767px) {

    .contact-us .form-container,
    .contact-us .contact-info {
        padding: 32px 0px;
        width: 100%
    }
}