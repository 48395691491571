.cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 32px;
}

.card {
    display: flex;
    flex-direction: column;
    min-height: fit-content;
    background-color: var(--light);
    border-radius: 10px;
    -moz-box-shadow: 1px 1px 6px 0px lightgrey;
    -webkit-box-shadow: 1px 1px 6px 0px lightgrey;
    box-shadow: 1px 1px 6px 0px lightgrey;
    width: calc((100%/3) - 1vw);
    margin-bottom: 1.5vw;
}

.card.hidden {
    visibility: hidden;
}

.card img {
    height: 260px;
    border-radius: 5px 5px 0 0;
}

.card div {
    display: flex;
    flex-direction: column;
    padding: 32px;
    border-radius: 0 0 5px 5px;
}

.card p {
    margin: 8px 0 16px 0;
}

.card a {
    color: var(--accent);
    text-decoration: none;
}

.card a:hover {
    text-decoration: underline;
}

.window-page .card img {
    object-position: top;
}
.upholstery-page .wall-upholstery .card img {
    object-position: 0 40%;
}

@media screen and (max-width: 1023px) {
    .card {
        width: calc(50% - 1vw);
        margin-bottom: 2vw;
    }

    .card div {
        padding: 24px;
    }

    .card.hidden {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    .card {
        width: 100% !important;
        margin-bottom: 3vw;
    }
}