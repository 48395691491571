.project-banner {
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: aliceblue;
    background-color: grey;
    background-image: url("../assets/custom-bedframe-and-curtains.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: inset 0 0 0 50vw rgba(2, 16, 20, 0.6);
}

.project-banner button {
    margin-top: 24px;
}

.project-banner h2 {
    color: var(--light);
    text-align: center;
}