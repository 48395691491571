.navbar {
    height: 72px;
    background-color: var(--light);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    box-shadow: 0 1px 5px 0px var(--grey);
}

.navbar .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.menu-icon {
    display: none;
}

.navbar .logo img {
    width: 250px;
}

.navbar .logo a {
    display: flex;
    align-items: center;
}

.nav-elements ul {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    width: 540px
}

.nav-elements ul a {
    color: var(--dark);
    text-decoration: none;
}

.nav-elements ul a.active {
    color: var(--accent);
    text-decoration: underline;
    text-underline-offset: 8px;
}

.contact-us-button-mobile {
    display: none;
}

@media screen and (max-width: 1023px) {

    .menu-icon {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .nav-elements {
        position: absolute;
        right: 0;
        top: 72px;
        background-color: var(--dark);
        width: 0px;
        height: 100vh;
        transition: all 0.3s ease-in;
        overflow: hidden;
        font-size: 20px;
        border-top: 1px solid var(--grey);
        text-transform: uppercase;
    }

    .nav-elements.active {
        width: 100%;
        padding: 16px 32px;
    }

    .navbar .logo img {
        width: 220px;
    }

    .navbar .menu-icon img {
        width: 32px;
        height: 32px;
    }

    .nav-elements ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .nav-elements ul li {
        margin-top: 32px;
    }

    .nav-elements ul a {
        color: var(--light);
    }

    .contact-us-button-desktop {
        display: none;
    }

    .contact-us-button-mobile {
        display: block;
    }

    .contact-us-button-mobile button {
        font-size: 18px;
    }
}