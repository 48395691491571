/* HOME */

.home h1 {
    font-size: clamp(30px, 5.5vw, 56px);
}

.home h2 {
    font-family: 'Source Sans 3', sans-serif;
    font-size: clamp(20px, 1.5vw, 24px);
    color: var(--accent);
}

.home .subtitle {
    font-family: 'Playfair Display', serif;
    font-size: clamp(24px, 3.5vw, 32px);
    line-height: 1.5;
    font-weight: bold;
    margin: 0;
}

/* HERO */

.hero-section {
    background-color: grey;
    background-image: url("../assets/custom-bedframe-and-curtains.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.6);
    height: 72vh;
    display: flex;
    align-items: center;
}

.hero-section .container {
    width: 100%;
}

.hero-section p {
    margin: 4vh 0;
    max-width: 560px;
    color: var(--light);
}

/* ABOUT */

.about-section {
    display: flex;
    align-items: center;
}

.about-section .subtitle {
    max-width: 480px;
}

.about-section img {
    max-width: 480px;
    max-height: 560px;
    margin-right: 5vw;
    border-radius: 5px;
}

.about-section p {
    margin-top: 24px;
    max-width: 90%;
}

/* STATS */

.stats-section {
    background-color: var(--accent);
}

.stats-section .container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}

.stats-section p {
    color: var(--light);
}

.stats-section .col {
    display: flex;
    align-items: center;
}

.stats-section .vl {
    height: 100px;
    border-left: 2px solid var(--light);
    margin: 0 16px;
}

.stats-section hr {
    display: none;
}

.stats-section .stat-big {
    font-size: clamp(40px, 5vw, 72px);
    line-height: 1;
    font-family: 'Playfair Display', serif;
    margin-right: 16px;
}

.stats-section .stat-small {
    font-weight: bold;
    max-width: 128px;
    font-size: 20px;
}

/* SERVICES */

/* PROJECTS */

.projects-section {
    background-color: var(--light-2);
}

.projects-section .container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.projects-section .images {
    display: flex;
    justify-content: space-between;
    margin: 32px 0;
}

.projects-section img {
    border-radius: 5px;
}

.projects-section .big-image {
    width: calc(40% - 0.5vw);
    height: 35vw;
}

.projects-section .small-images {
    width: calc(60% - 0.5vw);
    height: 35vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.projects-section .small-images div {
    height: calc(50% - 0.5vw);
    display: flex;
    justify-content: space-between;
}

.projects-section .small-images img {
    width: 49%;
    height: 100%;
}

@media screen and (max-width: 1023px) {
    body {
        /* background-color: darkred; */
    }

    .about-section {
        flex-direction: column-reverse;
    }

    .about-section p {
        max-width: 100%;
    }

    .about-section img {
        max-width: 100%;
        width: 100%;
        margin-right: 0;
        margin-top: 24px;
        object-position: 0 40%;
    }

    .home .subtitle {
        max-width: 100%;
    }

    .stats-section .stat-small {
        max-width: 100%;
    }

    .stats-section .vl {
        display: none;
    }

    .stats-section hr {
        display: block;
        width: 100%;
        opacity: 0.25;
        margin: 16px 0;
    }

    .projects-section .images {
        flex-direction: column;
    }

    .projects-section .big-image {
        width: 100%;
        height: 75vh;
        margin-bottom: 2vw;
    }

    .projects-section .small-images {
        width: 100%;
        height: 75vh;
    }

    .projects-section .small-images div {
        height: calc(50% - 1vw);
    }
}

@media screen and (max-width: 767px) {

    body {
        /* background-color: darkgreen; */
    }

    .projects-section h2,
    .projects-section .subtitle {
        width: 100%;
    }

    .projects-section .big-image {
        width: 100%;
        height: 40vh;
        margin-bottom: 2vw;
    }

    .projects-section .small-images {
        width: 100%;
        height: 40vh;
    }
}