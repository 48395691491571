.testimonials {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.testimonials h2 {
    font-family: 'Source Sans 3', sans-serif;
    font-size: 24px;
    color: var(--accent);
}

.testimonials .subtitle {
    font-family: 'Playfair Display', serif;
    font-size: 32px;
    line-height: 1.5;
    font-weight: bold;
    margin: 0;
}

.carousel {
    display: flex;
    align-items: center;
}

.carousel-item {
    display: flex;
    flex-direction: column;
    max-width: 640px;
    margin: 32px auto 56px auto;
}

.carousel .slide {
    justify-content: center;
}

.carousel .dot {
    width: 100%;
    background-color: var(--accent) !important;
}

.slide {
    display: flex;
}

.star-rating {
    color: goldenrod;
    font-size: 32px;
}

.review-text {
    margin: 24px 0;
    font-size: 20px;
    line-height: 1.5;
}

.review-author {
    font-size: 20px;
    font-style: italic;
}

@media screen and (max-width: 767px) {
    .carousel-root {
        width: 100%;
    }

    .review-text,
    .review-author {
        font-size: 18px;
    }

    .star-rating {
        font-size: 24px;
    }
}